import gql from 'graphql-tag';
import { ErrorFields, ImageFields } from '../common-fields.queries';

export const CREATE_TRILOGIE_BID = gql`
	mutation CreateTrilogieBid($input: CreateTrilogieBidInput!) {
		createTrilogieBid(input: $input) {
			... on CreateTrilogieBid {
				bidNumber
			}
			... on Error {
				...ErrorFields
			}
		}
	}
	${ErrorFields}
`;

export const SHOWROOM_DETAILS = gql`
	${ImageFields}
	query ShowroomDetails($id: String!) {
		showroomDetails(id: $id) {
			id
			storeInfo {
				logoUrl
				servingText
				appointmentRequired
				phoneNumber
				faxNumber
				appointmentUrl
				plumbingLocationUrl
			}
			locationImages {
				...ImageFields
			}
			address {
				addressLine1
				addressLine2
				city
				state
				zipCode
			}
			headline
			aboutUs
			type
			locationType
			hours {
				dayName
				hours {
					startTime
					endTime
				}
			}
			products
			services
			brands
			branchMessage
			proAssociations {
				name
				logoUrl
				url
			}
			locationUrl
			nearbyBusinesses {
				name
				url
			}
			merchandisingBanners {
				title
				description
				linkUrl
				imageUrl
			}
		}
	}
`;

export const SHOWROOMS_BY_LOCATION = gql`
	query ShowroomsByLocation($input: ShowroomsByLocationInput!) {
		showroomsByLocation(input: $input) {
			id
			name
			locationTypes
			address {
				addressLine1
				addressLine2
				city
				state
				zipCode
				phoneNumber
				latitude
				longitude
			}
			branchPageUrl
		}
	}
`;

const TrilogiePricingFields = gql`
	fragment TrilogiePricingFields on TrilogiePricing {
		id
		accountNumber
		showroomId
	}
`;

export const TRILOGIE_PRICING = gql`
	${TrilogiePricingFields}
	query TrilogiePricing {
		trilogiePricing {
			...TrilogiePricingFields
		}
	}
`;

export const ADD_TRILOGIE_PRICING = gql`
	mutation AddTrilogiePricing($input: TrilogiePricingInput!) {
		addTrilogiePricingWithError(input: $input) {
			... on TrilogiePricing {
				...TrilogiePricingFields
			}
			... on Error {
				...ErrorFields
			}
		}
	}
	${TrilogiePricingFields}
	${ErrorFields}
`;

export const REMOVE_TRILOGIE_PRICING = gql`
	${TrilogiePricingFields}
	mutation RemoveTrilogiePricing {
		removeTrilogiePricing {
			...TrilogiePricingFields
		}
	}
`;
